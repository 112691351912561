
import {mapGetters} from "vuex";
import LoadingSpinner from "./loading-spinner";

export default {
    name: "Subnavs",
    components: {LoadingSpinner},
    computed: {
        ...mapGetters({
            currentCompany: "app/currentCompany",
        }),
        isComposeActive() {
            if (this.$route.name === "emails-id") {
                return true;
            } else if (this.$route.name === "emails-compose") {
                return true;
            } else {
                return false;
            }

        },
        isTargetingGroupActive() {
            return this.$route.name === "companies-targeting-groups-id";
        },
    }
}
