
import {mapActions, mapGetters} from "vuex";
import currentBreakpoint from "../mixins/currentBreakpoint";
import InitialsCircle from "./helpers/initials-circle.vue";
import SearchBox from "./search-box.vue";
import Subnavs from "./subnavs.vue";
import logo from "~/assets/images/icon-white.png"
import logoInactive from "~/assets/images/icon-grey.png"

export default {
    name: "Navbar2",
    components: {Subnavs, SearchBox, InitialsCircle},
    mixins: [currentBreakpoint],
    props: {
        pages: {
            type: [Array, Object],
            required: true,
        }
    },
    data() {
        return {
            logo: logoInactive,
        }
    },
    computed: {
        ...mapGetters({
            userData: "app/userData",
            companyHistory: "app/companyHistory",
            contactHistory: "app/contactHistory",
            currentRoute: "app/currentRoute",
        }),
        logoActive() {
            return logo;
        },
        logoInactive() {
            return logoInactive;
        },
        isLoggedIn() {
            return this.$auth.loggedIn;
        },
        findDefaultIeModule() {
            if (this.isUserWithRoleCorporateAccess) {
                return '/events';
            }
            if (this.isUserWithRoleResearchAnalyst) {
                return '/events/call-logs';
            }
            return '/events';
        },
    },
    watch: {
        currentRoute() {
            this.closeNavbarCollapse();
        }
    },
    mounted() {
        const headerElement = document.querySelector('header');
        const mainContainer = document.querySelector('#mainContainer');

        function adjustPadding(entries) {
            for (const entry of entries) {
                if (entry.target === headerElement) {
                    const navHeight = headerElement.offsetHeight;
                    mainContainer.style.marginTop = `${navHeight}px`;
                    mainContainer.style.minHeight = `calc(100vh - 86px - ${navHeight}px)`;
                }
            }
        }

        const resizeObserver = new ResizeObserver(adjustPadding);
        resizeObserver.observe(headerElement);
    },
    methods: {
        ...mapActions({
            setUserData: "app/setUserData",
            setPreviousRoute: "app/setPreviousRoute",
            removeFromCompanyHistory: "app/removeFromCompanyHistory",
            removeFromContactHistory: "app/removeFromContactHistory",
        }),
        closeNavbarCollapse() {
            // console.log("Closing Navbar...");
            if (this.currentBreakpoint !== "xxl") {
                const collapseNodes = document.querySelectorAll(".navbar-collapse");
                for (let i = 0; i < collapseNodes.length; i++) {
                    // console.log("Collapsing Navbar ", collapseNodes[i]);
                    /* eslint-disable */
                    const collapse = new this.$bootstrap.Collapse(collapseNodes[i], {
                        toggle: false
                    });
                    collapse.hide();
                }
            }
        },
        deleteCompanyFromHistory(id) {
            this.removeFromCompanyHistory(id);
        },
        deleteContactFromHistory(id) {
            this.removeFromContactHistory(id);
        },
        async logout() {
            this.closeNavbarCollapse();
            await this.$auth.logout()
            this.$nextTick(() => {
                this.setUserData(null);
                this.setPreviousRoute(null);
            });
        },
        deleteFavoritePage(page, hash) {
            page['hash'] = hash;
            this.$axios.post('/api/delete_favorite_page', page)
                .then((response) => {
                    this.$emit('fav-pages-updated');
                })
        }
    },
}
